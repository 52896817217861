<template>
  <div class="dataSourceList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>类型：</span>
        <a-select v-model="type" placeholder="请选择类型" allowClear>
          <a-select-option
            v-for="item in typeList"
            :key="item.id"
            :value="item.type"
          >
            {{ item.type }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        <span>数据库名称：</span>
        <a-input
          placeholder="请输入数据库名称"
          v-model.trim="databaseName"
          @pressEnter="query(1)"
          allowClear
          @change="allowClearChange"
        />
      </div>
      <div class="searchConditionItem">
        <span>host：</span>
        <a-input
          v-model.trim="host"
          placeholder="请输入host"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>x：</span>
        <a-input
          v-model.trim="x"
          placeholder="请输入x"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>y：</span>
        <a-input
          v-model.trim="y"
          placeholder="请输入y"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="middle"
      :rowKey="(record) => record.id"
      :scroll="{ x: true }"
    >
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="record.enabled"
          @change="modifyClick(record, true)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="testConnect(record)">测试连接</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="数据库类型:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select v-model="typeAdd" placeholder="请选择数据库类型" allowClear>
            <a-select-option
              v-for="item in typeList"
              :key="item.id"
              :value="item.type"
              >{{ item.type }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="数据源名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="dataSourceNameAdd" placeholder="请输入数据源名称" />
        </a-form-item>
        <a-form-item
          label="数据库名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="databaseNameAdd" placeholder="请输入数据库名称" />
        </a-form-item>
        <a-form-item
          label="host:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="hostAdd" placeholder="请输入host" />
        </a-form-item>
        <a-form-item
          label="端口:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="portAdd" placeholder="请输入端口" />
        </a-form-item>
        <a-form-item
          label="用户:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="userAdd" placeholder="请输入用户" />
        </a-form-item>
        <a-form-item
          label="坐标x:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="xAdd" placeholder="请输入坐标x" />
        </a-form-item>
        <a-form-item
          label="坐标y:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="yAdd" placeholder="请输入坐标y" />
        </a-form-item>
        <a-form-item
          label="启用状态:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-switch
            v-model="enabledAdd"
            checkedChildren="已启用"
            unCheckedChildren="已禁用"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/dataSourceList.js";

export default {
  name: "dataSourceList",
  data() {
    return {
      title: "",
      type: undefined,
      databaseName: "",
      host: "",
      x: "",
      y: "",
      typeAdd: "",
      dataSourceNameAdd: "",
      databaseNameAdd: "",
      hostAdd: "",
      portAdd: undefined,
      userAdd: "",
      xAdd: "",
      yAdd: "",
      enabledAdd: true,
      groupId: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "类型",
          dataIndex: "type",
        },
        {
          title: "数据源名称",
          dataIndex: "dataSourceName",
        },
        {
          title: "数据库名称",
          dataIndex: "databaseName",
        },
        {
          title: "host",
          dataIndex: "host",
        },
        {
          title: "端口",
          dataIndex: "port",
        },
        {
          title: "用户",
          dataIndex: "user",
        },
        {
          title: "坐标x",
          dataIndex: "x",
        },
        {
          title: "坐标y",
          dataIndex: "y",
        },
        {
          title: "启用状态",
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      typeList: [
        { id: 1, type: "MYSQL" },
        { id: 2, type: "CLICKHOUSE" },
      ],
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        type: this.type,
        databaseName: this.databaseName,
        host: this.host,
        x: this.x,
        y: this.y,
      };
      this.loadingTable = true;
      api
        .datasourcePage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val, submit) {
      this.title = "修改";
      this.groupId = val.id;
      this.typeAdd = val.type;
      this.databaseNameAdd = val.databaseName;
      this.dataSourceNameAdd = val.dataSourceName;
      this.hostAdd = val.host;
      this.portAdd = val.port;
      this.userAdd = val.user;
      this.xAdd = val.x;
      this.yAdd = val.y;
      this.enabledAdd = val.enabled;
      if (submit) {
        this.add_submit();
      } else {
        this.addKeyVisible = true;
      }
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.typeAdd = "MYSQL";
      this.databaseNameAdd = "";
      this.dataSourceNameAdd = "";
      this.hostAdd = "";
      this.portAdd = undefined;
      this.userAdd = "";
      this.xAdd = "";
      this.yAdd = "";
      this.enabledAdd = false;
    },
    // 确定新增
    add_submit() {
      let data = {
        type: this.typeAdd,
        databaseName: this.databaseNameAdd,
        dataSourceName: this.dataSourceNameAdd,
        host: this.hostAdd,
        port: this.portAdd,
        user: this.userAdd,
        x: this.xAdd,
        y: this.yAdd,
        enabled: this.enabledAdd,
      };
      if (this.title === "新增") {
        api.addData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        api.editData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.groupId = "";
      this.typeAdd = "";
      this.databaseNameAdd = "";
      this.dataSourceNameAdd = "";
      this.hostAdd = "";
      this.portAdd = undefined;
      this.userAdd = "";
      this.xAdd = "";
      this.yAdd = "";
      this.enabledAdd = undefined;
    },
    testConnect(val) {
      this.loadingTable = true;
      let data = {
        id: val.id,
      };
      api.testConnect(data).then((res) => {
        if (res.result === 200) {
          this.loadingTable = false;
          if (res.data) {
            this.$message.success("连接成功");
          } else {
            this.$message.error("连接失败");
          }
        }
      });
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteData(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
